import { render, staticRenderFns } from "./NewProfile.vue?vue&type=template&id=5c16b166&scoped=true"
import script from "./NewProfile.vue?vue&type=script&lang=js"
export * from "./NewProfile.vue?vue&type=script&lang=js"
import style0 from "./NewProfile.vue?vue&type=style&index=0&id=5c16b166&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c16b166",
  null
  
)

export default component.exports